.social-bg {

    width: 40px;
    position: absolute;
    right: 0;
    top: 200px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px 0;
    z-index: 9;
}

.social-bg img {
    width: 25px;
    height: 25px;
    margin-bottom: 6px;
    margin-top: 6px;
}