.logo-size {
    width: 180px;
}

.nav-container {
    padding: 0 200px;
}

.nav-fix {
    position: fixed;
    top: 0;
    box-shadow: 3px 5px 8px rgba(39, 39, 39, 0.144);
}

.menu :where(li)> :where(*:not(ul)) {
    gap: 1rem;
    padding-left: .75rem;
    padding-right: 1rem;
    padding-top: 0.10rem;
    padding-bottom: 0.10rem;
    color: currentColor;
}


::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fb4226;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fb4226;
}

@media screen and (max-width:1700px) {
    .nav-container {
        padding: 0 100px;
    }
}

@media screen and (max-width:1600px) {
    .nav-container {
        padding: 0 60px;
    }
}

@media screen and (max-width:600px) {
    .nav-container {
        padding: 0 20px;
    }
}

@media screen and (max-width:480px) {
    .nav-container {
        padding: 0 10px;
    }
}